.g-area {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  background-color: $color-primary-light;
  @media screen and (max-width: 1399px) {
    padding: $space-3 0;
  }
  &__container {
    display: flex;
    gap: $space-4;
    @include container-100;
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }

  &__infog {
    margin-bottom: $space-3;
  }

  &__content {
    flex: 1;
  }

  &__servicios {
    flex: 1.2;
  }
  &__servicios-title {
    margin-bottom: $space-2;
    font-size: $header-2;
    color: $color-primary-dark;
    @include typo-alt-400;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }

  &__cita {
    border-left: 4px solid $color-white;
    padding-left: $space-2;
    margin-bottom: $space-2;
    padding-top: $space-3;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: $space-2;

      display: block;
      background: url("../../dist/assets/ico/ico-quote.svg") no-repeat;
      width: 50px;
      height: 50px;
      background-size: contain;
    }
    p {
      color: $color-primary-dark;
      font-size: $font-size-big;
      line-height: 1.6;
    }
  }
}

.area-servicios {
  display: flex;
  flex-direction: column;
  gap: $space-1;
  @media screen and (max-width: 767px) {
    gap: $font-size;
  }
  &__ico {
    padding-top: calc($font-size / 3);
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc($space-1);
  }
  &__content {
  }

  &__titulo {
    font-size: $header-4;
    font-weight: $font-weight-normal;
    color: $color-white;
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
  }

  &__descripcion {
  }
}

.ico-box {
  width: 25px;
  height: 25px;
  background: $color-white;
  color: $color-primary-dark;
  font-size: $header-4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  &--rounded {
    border-radius: 100px;
  }
  &--color-primary {
    background-color: $color-primary-dark;
    color: $color-white;
  }
}

.g-area-proyectos {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    @include container-100;
  }
  &--primary {
    background-color: $color-primary-light;
  }
  &__header {
    margin-bottom: $space-2;
  }

  &__pretitle {
    font-size: $header-4;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $header-2;
    color: $color-primary-dark;
    @include typo-alt-300;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }
}

.g-areas {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  background: $color-primary-light;

  &--archive {
    padding-top: $space-4 !important;
    @media screen and (max-width: 767px) {
      padding-top: $space-2 !important;
    }
  }

  &__container {
    @include container-100;
  }

  &__header {
    margin-bottom: $space-3;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }

  &__main {
  }
}
.container {
}

.areas-list {
  display: flex;
  gap: $space-4;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: $space-1;
    flex-direction: column-reverse;
  }
  &__left {
    flex: 0 0 25vw;
    @media screen and (max-width: 767px) {
      flex: 0 0 1;
      display: flex;
      align-items: flex-start;
      gap: $space-3;
      padding: $font-size 0;
    }
    @media screen and (max-width: 575px) {
      gap: $space-2;
    }
    @media screen and (max-width: 480px) {
      gap: $space-1;
    }
    .infog {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 30%;
      }
    }
  }
  &__cta {
    padding-top: $space-1;
    margin-top: $space-1;
    width: 100%;
    border-top: 1px dashed $color-primary;
    p {
      color: $color-primary-dark;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    .boton {
      margin-top: $space-1;
    }
  }
  &__cover {
    margin-top: $space-1;
    position: relative;
    border-radius: $border-radius-min;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: $space-2;
    margin-bottom: 0;
    padding-top: $space-1;
    padding-bottom: $space-1;
    padding: $font-size-big 0;
    border-bottom: 1px solid $color-primary-dark;
    border-bottom: 1px solid $color-white;
    @include typo-alt-400;
    overflow: hidden;

    &:last-child {
      border-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      padding-top: $font-size;
      padding-bottom: $font-size;
    }
  }
  &__link {
    flex: 1;
    display: flex;
    line-height: 1.2;
    text-decoration: none;
    color: $color-primary-dark;
    font-size: $header-3;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
    &:hover {
      color: $color-white;
      transition: $trans;
      // margin-left: $space-1;
    }
  }
}

.infog {
  display: flex;
  width: 100%;
  gap: $space-2;
  @media screen and (max-width: 767px) {
    gap: $font-size;
  }
  &__item {
    flex: 1;
  }
  &__cercle {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 100%;
    background-color: $color-white;
    border-radius: 50%;
    position: relative;
    &::after {
      border-radius: 50%;
      position: absolute;
      content: "";
      display: block;

      border: 1px dashed $color-primary-light;
      inset: calc($font-size-smaller);
    }
    &--logo {
      background-color: $color-primary-dark;
      &::after {
        // border-color: $color-primary-light;
        border: none;
      }
      svg,
      img {
        width: 40%;
        height: auto;
      }
    }
    // &--ico {
    //   img {
    //     width: 35%;
    //     height: auto;
    //   }
    // }
    &--ico {
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        border-color: $color-white;
      }
    }
  }
}

.aps {
  padding-bottom: $space-2;
  &__item {
    padding: calc($font-size / 2);
    img {
      border-radius: $border-radius-min;
      aspect-ratio: 16/11;
      width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 2;
        background: rgba($color: $color-dark-alt, $alpha: 0.5);
        mix-blend-mode: multiply;
      }
    }
  }
}
