.home {
  .header {
    position: fixed !important;
    width: 100%;
    top: 0;
  }
  .header:not(.header--sticky) {
    #logo {
      path {
        fill: $color-white;
      }
    }
    .menu-item {
      a {
        color: $color-white !important;
      }
    }

    li.menu-item-has-children .sub-menu li a {
      color: $color-dark !important;
    }

    .header__search,
    .header__burger {
      i {
        color: $color-white;
      }
    }
  }
}

// .header {
//   #logo {
//     path {
//       fill: $color-white;
//     }
//   }
//   .menu-item {
//     a {
//       color: $color-white !important;
//     }
//   }
// }

.header {
  padding: $space-1 0;
  position: sticky !important;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: $trans;
  &--sticky {
    padding: $font-size-smaller 0;
    box-shadow: $box-shadow;
    z-index: 10;
    transition: $trans;
    background-color: $color-white;
  }
  &__container {
    display: flex;
    gap: $space-1;
    align-items: center;
    @include container-100;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }
  &__burger {
    a {
      color: $color-dark;
      font-size: $header-3;
    }
    @media screen and (min-width: 901px) {
      display: none;
    }
  }
  &__logo {
    #logo {
      width: 120px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 80px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__cta {
    @media screen and (max-width: 900px) {
      margin-left: auto;
    }
  }
  &__search {
    .button-search {
      border: 0;
      background: none;
      color: $color-dark;
      font-size: $font-size-big;
    }
  }
}

.top-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-1;
  &__title {
    text-align: center;
    font-size: $header-2;
    color: $color-primary;
  }
}

.top-search {
  .searchform {
    @include container-100;
    & > div {
      position: relative;
      input[type="text"] {
        width: 100%;
        font-size: $header-2;
        padding: $font-size;
        border-radius: $border-radius;
      }
      input[type="submit"] {
        font-size: $header-2;
        position: absolute;
        right: $space-1;
        top: $font-size;
        bottom: $font-size;
        background: none;
        border: none;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}
