.page-title {
  padding: calc($space-2) 0 $space-2;
  position: relative;
  &--dark {
    background-color: $color-dark;
  }
  &__container {
    @include container-100;
    position: relative;
  }
  &__pre {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-primary;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
      margin-bottom: $font-size;
    }
  }
  &__h {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1.5;
    @include typo-alt-400;
    color: $color-dark;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
      line-height: 1.3;
    }
    span {
      font-style: italic;
      color: $color-primary;
    }
  }
  &__description {
    font-size: $header-3;
    text-wrap: balance;
    color: $color-grey-3;
    @media screen and (min-width: 992px) {
      max-width: 60%;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-5;
    }
  }
  &__breadcrumbs {
    margin-top: calc($font-size / 2);
    font-size: $font-size-smaller;
    color: $color-grey-3;
    a {
      color: $color-secondary;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }
  &__overlay {
    position: absolute;
    overflow: hidden;
    inset: 0;
    // top: 0;
    // bottom: $font-size-smaller;
    // right: $font-size-smaller;
    z-index: -1;
    // width: 50vw;
    border-radius: $border-radius;
    &::before {
      z-index: 1;
      content: "";
      position: absolute;
      inset: 0;
      background: red;
      background: rgba($color: rgba(116, 109, 94), $alpha: 0.6);
      // mix-blend-mode: multiply;
    }
    img {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: grayscale(0.8);
    }
  }
}
