.sp {
  padding: $space-3 0;
  background-color: $color-primary-light;

  &__container {
    @include container-100;
  }

  &__item {
    border-bottom: 1px dashed $color-white;
    padding-bottom: $space-1;
    margin-bottom: $space-1;
  }

  &__article {
  }

  &__title {
    font-family: $typo-alternative;
    font-size: $header-1;
    a {
      color: $color-white;
      text-decoration: none;
    }
  }

  &__entry {
    .read-more {
      display: block;
      color: $color-dark;
      font-weight: $font-weight-normal !important;
      background: $color-accent;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      margin-left: auto !important;
      text-decoration: none !important;
      padding: 2px 16px;
      border-radius: 50px;
    }
  }
}
