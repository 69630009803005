.hero {
  height: 100vh;
  background: $color-dark-alt;
  position: relative;
  &__video {
    z-index: 0;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
      background: rgba($color: $color-dark-alt, $alpha: 0.5);
      mix-blend-mode: multiply;
    }
  }
  &__player {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    object-fit: cover;
    filter: grayscale(0.8);
  }
  &__container {
    position: relative;
    padding-bottom: $space-4;
    @include container-100;
    z-index: 2;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: $space-2;
      padding-bottom: $space-3;
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: $space-2;
      padding-bottom: $space-2;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: $space-2;
  }

  &__title {
    font-size: $display-1;
    font-family: $typo-alternative;
    line-height: 1;
    color: $color-white;
    margin-bottom: $space-1;
    span {
      // color: $color-accent;
    }
    @media screen and (max-width: 991px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-1;
    }
  }

  &__subtitle {
    color: $color-white;
    font-size: $header-3;
    text-wrap: balance;
    @media screen and (min-width: 992px) {
      width: 60%;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-3-res;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-big;
    }
  }

  &__description {
    font-size: $header-4;
    margin-bottom: $space-2;
  }

  &__actions {
    display: flex;
    gap: $space-1;
  }
  &__arrow {
    position: absolute;
    right: $space-4;
    bottom: $space-4;
    z-index: 2;
    cursor: pointer;
    .style-svg {
      animation: arrow 1s infinite alternate;
      width: 20px;
      height: auto;
      path {
        fill: $color-white;
      }
      @media screen and (max-width: 575px) {
        width: $font-size;
        height: auto;
      }
    }
    @media screen and (max-width: 767px) {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

@keyframes arrow {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.claim {
  padding: $space-4 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    @include container-100;
  }
  &__cercles {
    border-top: 1px dashed $color-grey-5;
    padding-top: $space-2;
    @media screen and (max-width: 991px) {
      overflow-x: auto;
      padding-bottom: $font-size;
    }
  }
  &__title {
    font-size: $font-weight-medium;
    font-size: $header-2;
    text-wrap: balance;
    font-weight: bold;
    color: $color-primary;
    margin-bottom: $space-2;
    @media screen and (min-width: 992px) {
      max-width: 80vw;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-3;
    }
    a {
      font-weight: $font-weight-normal;
      color: $color-dark-alt;
      text-decoration: none;
    }
    em {
      font-style: normal;
      font-family: $typo-alternative;
      font-weight: 400;
      font-size: 3.2vw;
      color: $color-text-body;
      @media screen and (max-width: 991px) {
        font-size: $header-1-res;
      }
      @media screen and (max-width: 767px) {
        font-size: $header-2;
      }
      @media screen and (max-width: 575px) {
        font-size: $header-2-res;
      }
    }
    span {
      color: $color-dark;
    }
  }
}

.cc {
  display: flex;
  justify-content: flex-start;
  gap: $space-1;
  gap: $font-size-small;
  align-items: center;
  &__cercle {
    width: 12vw;
    height: 12vw;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 200px;
    max-height: 200px;
    min-width: 134px;
    min-height: 134px;
    &::after {
      content: "";
      inset: 0.5vw;
      position: absolute;
      border-radius: 200px;
      border: 1px dashed rgba($color: #fff, $alpha: 0.7);
    }
    &--big {
      // width: 12vw;
      // height: 12vw;
    }
    span {
      font-size: 1.3vw;
      text-align: center;
      padding: 1vw;
      @media screen and (min-width: 1401px) {
        font-weight: 1vw;
      }
      @media screen and (min-width: 1700px) {
        font-size: 24px;
        padding: $font-size;
        font-weight: $font-weight-normal;
      }
      @media screen and (max-width: 991px) {
        font-size: $font-size;
      }
    }
  }
  &__item {
    background-color: $color-accent;
    span {
      color: $color-white;
    }
  }
  &__end {
    background-color: $color-dark-alt;
    span {
      @include typo-alt-400;
      color: $color-white;
      font-size: 1.4vw;
      line-height: 1.3;
      @media screen and (min-width: 1700px) {
        font-size: 24px;
        padding: $font-size;
        font-weight: $font-weight-normal;
      }
      @media screen and (max-width: 991px) {
        font-size: $font-size-big;
        font-weight: $font-weight-normal;
      }
    }
  }

  &__sign {
    font-size: $header-2;
  }

  &__end {
  }
}

.hero-legacy {
  .slick-dots {
    bottom: -50px !important;
  }
}

.g-section {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-4 0;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    @include container-100;
  }
  &__header {
    margin-bottom: $space-3;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-2;
    }
    &--clientesArchivo {
      margin-bottom: $space-1;
      @media screen and (max-width: 767px) {
        margin-bottom: $space-2;
      }
    }
  }
  &__main {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-4 $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3 $space-2;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }
  &__featured {
    margin-top: $space-3;
  }
  &--proyectos {
    background-color: $color-light;
    // background-color: lighten($color: $color-dark, $amount: 67%);
  }
  &--clientesArchivo {
    background-color: $color-grey-6;
  }
  &--partnersArchivo {
    background-color: $color-grey-5;
  }
}

.thumb-destacado {
  // padding: $space-2;
  padding: $space-1;
  background-color: $color-white;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: $space-2;
  border-radius: $border-radius;
  text-decoration: none !important;
  transition: $trans;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $space-1;
  }
  &:hover {
    box-shadow: $box-shadow;
    transition: $trans;
  }
  &__cover {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 6/4;
      object-fit: cover;
      border-radius: $border-radius;
      border: 1px solid $color-grey-6;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__cliente {
  }
  &__fechas {
    font-size: $header-4;
    color: $color-accent;
  }
  &__titulo {
    font-size: $header-2;
    font-family: $typo-alternative;
    color: $color-dark;
  }

  &__descripcion {
    font-size: $header-3;
    font-family: $typo-alternative;
    color: $color-grey-3;
  }
  &__cofunded {
    margin-top: $font-size;
    img {
      width: 200px;
      height: auto;
    }
  }
  &__actions {
    display: flex;
    gap: $space-1;
    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
}

.g-somos {
  background-color: $color-dark;
  color: $color-white;
  padding: $space-3 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  overflow: hidden;
  &__container {
    @include container-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 992px) {
      max-width: 60%;
    }
  }

  &__title {
    font-size: $display-2;
    @include typo-alt-400;
    position: relative;
    span {
      padding-bottom: 3px;
      display: block;
      right: -1.9rem;
      top: 0.5rem;
      background-color: $color-primary;
      font-size: $header-4;
      line-height: 0.9;
      color: $color-white;
      position: absolute;
      width: 40px;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      // @media screen and (max-width: 991px) {
      //   font-size: $display-2;
      // }
      @media screen and (max-width: 575px) {
        width: 35px;
        top: 0.25rem;
      }
      // @media screen and (max-width: 480px) {
      //   font-size: $display-3;
      // }
    }
    @media screen and (max-width: 991px) {
      font-size: $display-2;
    }
    @media screen and (max-width: 575px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 480px) {
      font-size: $display-3;
    }
  }

  &__description {
    font-size: $font-size;
    font-weight: $font-weight-normal;
    line-height: 1.6;
  }

  &__actions {
    display: flex;
    gap: $space-1;
    margin-top: $space-1;
  }
}

.clientes-slider {
  padding-bottom: $space-1;
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
    margin-bottom: 0 !important;
  }
  &.slick-slider {
    .slick-dots {
      bottom: -10px !important;
    }
  }

  &__footer {
    padding-top: $font-size;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-2;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      row-gap: 0;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: $space-5;
    margin-bottom: $font-size;
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      // display: none;
    }
    i {
      font-size: 3rem;
      color: $color-dark;
    }
  }

  &__dots {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
    .slick-dots {
      position: relative;
      top: initial;
      bottom: 5px;
    }
  }

  &__arrows--prev {
  }

  &__arrows--next {
  }

  &__actions {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__item {
    padding: calc($font-size / 2);
  }
  &__cover {
    border: 2px solid $color-grey-6;
    border-radius: $border-radius;
    // border: 1px solid red;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      // width: 100%;
      // height: auto;
      // max-height: 60%;
      // max-width: 60%;
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 50%;
      object-fit: contain;
    }
  }
}

// $dotsize

ul.slick-dots {
  li {
    width: 15px;
    height: 15px;
    button {
      padding: 3px !important;
      width: 15px;
      height: 15px;
      &::before {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.of {
  // padding: $space-2;
  padding: $space-1;
  background-color: $color-white;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: $space-2;
  border-radius: $border-radius;
  text-decoration: none !important;
  transition: $trans;
  padding-bottom: 0;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $space-1;
  }
  &:hover {
    box-shadow: $box-shadow;
    transition: $trans;
  }
  &__cover {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 6/4;
      object-fit: cover;
      border-radius: $border-radius;
      border: 1px solid $color-grey-6;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__cliente {
  }
  &__fechas {
    font-size: $header-4;
    color: $color-accent;
  }
  &__titulo {
    font-size: $header-2;
    font-family: $typo-alternative;
    color: $color-dark;
  }

  &__descripcion {
    font-size: $header-3;
    font-family: $typo-alternative;
    color: $color-grey-3;
  }
  &__cofunded {
    margin-top: $font-size;
    img {
      width: 200px;
      height: auto;
    }
  }
  &__actions {
    display: flex;
    gap: $space-1;
    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
  &__bg {
    grid-column: 1 / 3;
    img {
      width: 100%;
      height: auto;
    }
  }
}
