body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  font-family: $typo-primary;
}

a {
  color: $color-accent;
  transition: $trans;
  &:hover {
    text-decoration: none;
    transition: $trans;
  }
}

.container {
  &--max {
    @media screen and (min-width: 1320px) {
      max-width: 1500px !important;
    }
  }
}

body.admin-bar {
  &.home {
    .header {
      top: 32px;
    }
  }
  &:not(.home) {
    .header {
      top: 32px;
    }
  }
}

.link {
  &--bold {
    font-weight: $font-weight-bold;
  }
  &--underline {
  }
}
