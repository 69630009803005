@mixin button {
  padding: 0.6rem 1.25rem; // cambiar tamaño
  border-radius: 50px; // cambiar radius
  font-size: 1rem;
  font-weight: $font-weight-medium;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.325rem 1rem;
    font-size: $font-size-small;
  }
  &--smr {
    padding: 0.125rem 0.5rem;
    font-size: $font-size-small;
  }
  &:hover {
    box-shadow: $box-shadow-small;
    transform: translateY(-2px);
    transition: $trans;
  }
}

@mixin container-100 {
  padding-left: $space-2;
  padding-right: $space-2;
  @media screen and (min-width: 1401px) {
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 991px) {
    padding-left: $space-1;
    padding-right: $space-1;
  }
  @media screen and (max-width: 767px) {
    padding-left: $font-size;
    padding-right: $font-size;
  }
}

@mixin typo-alt-300 {
  font-weight: $font-weight-light;
  font-family: $typo-alternative;
}

@mixin typo-alt-400 {
  font-weight: $font-weight-normal;
  font-family: $typo-alternative;
}
