.footer {
  padding-top: $space-2;
  padding-bottom: $space-2;
  background-color: $color-dark-alt;
  &__container {
    @include container-100;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-1;
  }

  &__left {
  }
  &__logo {
    width: 130px;
    height: auto;
  }

  &__right {
  }

  &__copy {
    font-size: $font-size-smaller;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      gap: 1rem;
    }
  }
}

.menu-legal {
  display: flex;
  margin: 0;
  padding: 0;
  gap: $font-size;
  list-style: none;
  .menu-item {
    a {
      text-decoration: none;
      color: $color-white;
      font-size: $font-size-smaller;
    }
  }
  @media screen and (max-width: 480px) {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.25rem;
  }
}

.zoom-social-icons-list {
  &__item {
  }
  .zoom-social_icons-list__link {
    .social-icon {
      background-color: $color-white !important;
      &::before {
        color: $color-dark !important;
      }
    }
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-2;
    flex-wrap: wrap;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
