.equipo-header {
  background-color: $color-primary-light;
  padding: $space-3 0 2rem 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0 1.25rem 0;
  }
  &__container {
    @include container-100;
  }

  &__grid {
    display: flex;
    gap: $space-4;
    align-items: center;
    @media screen and (max-width: 991px) {
      gap: $space-3;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: $space-1;
    }
  }

  &__ico {
    flex: 0 0 15vw;
    svg,
    img {
      width: 150px;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 124px;
      max-height: 124px;
    }
  }

  &__content {
    font-size: $header-3;
    color: $color-white;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-big;
    }
  }
}

.equipo-content-grid {
  background-color: $color-primary-light;
  padding: 2rem 0 $space-3 0;
  @media screen and (max-width: 575px) {
    padding: 1.25rem 0 $space-2 0;
  }

  &__titulo {
    font-size: $header-1;
    font-family: $typo-alternative;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }

  &__container {
    @include container-100;
  }

  &__3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $space-2;
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 767px) {
      gap: $space-2;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-2;
    margin-bottom: $space-3;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
}

.equipo-content-grid__3 .equipo-content:nth-last-child(1) {
  @media screen and (min-width: 576px) and (max-width: 991px) {
    grid-column: 1 / span 2;
  }
}

.equipo-content {
  border-top: 1px solid $color-grey-3;
  padding-top: $space-2;
  @media screen and (max-width: 575px) {
    padding-top: $space-1;
  }
  &--dark {
    .post-body {
      h4 {
        color: $color-primary-dark !important;
      }
    }
    .equipo-content {
      &__title {
        color: $color-primary-dark !important;
      }
    }
  }
  &__container {
  }
  &__foto {
    margin-bottom: $space-1;
    img {
      max-width: 100%;
      height: auto;
      border-radius: $border-radius-min;
    }
  }
  &__grid {
  }

  &__title {
    // text-transform: uppercase;
    color: $color-white;
    margin-bottom: $space-1;
    font-size: $header-3;
    font-family: $typo-alternative;
    line-height: 1.3;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }

  &__descripcion {
  }

  &__logo {
    margin-bottom: $space-1;
    svg,
    img {
      width: 30%;
      height: auto;
      max-width: 136px;
    }
  }
}

.post-body {
  h2,
  h3,
  h4,
  strong {
    color: $color-primary-dark;
  }
  .cita,
  h3 {
    font-size: $header-3;
    line-height: 1.6;
    font-weight: $font-weight-normal;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-big;
    }
  }
  h4 {
    color: $color-white;
    margin-bottom: $space-1;
    font-size: $header-3 !important;
    font-family: $typo-alternative;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      margin-bottom: $font-size;
      padding-top: $font-size;
      font-size: $header-2-res !important;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3 !important;
    }
  }
  hr {
    border-top: 1px solid $color-grey-3;
    opacity: 1;
    margin: $space-1 0;
  }
}

.info-cercle {
  background-color: $color-white;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: "";
    border-radius: 1000px;
    position: absolute;
    inset: 5%;
    border: 1px dashed $color-grey-5;
  }
  img,
  svg {
    width: 40%;
    height: auto;
    path {
      fill: $color-primary-dark;
    }
  }
}

.team {
  padding: $space-4 0;
  background-color: $color-primary-light;
  color: $color-white;
  &__container {
    @include container-100;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $space-3 $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: $space-3 $space-1;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }

  &__item {
  }

  &__cover {
    margin-bottom: $font-size;
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      border-radius: $border-radius-min;
    }
  }

  &__name {
    font-family: $typo-alternative;
    font-size: $header-3;
  }

  &__position {
    font-size: $font-size-small;
  }
}
