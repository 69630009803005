.publicaciones-header {
  background-color: $color-light;
  padding: $space-3 0 $space-3 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0 1.25rem 0;
  }
  &__container {
    @include container-100;
  }

  &__grid {
    display: flex;
    gap: $space-4;
    align-items: center;
    @media screen and (max-width: 991px) {
      gap: $space-3;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: $space-1;
    }
  }

  &__ico {
    flex: 0 0 15vw;
    // svg,
    // img {
    //   width: 150px;
    //   height: auto;
    // }
    @media screen and (max-width: 767px) {
      max-width: 124px;
      max-height: 124px;
    }
  }

  &__content {
  }
  &__titulo {
    font-size: $header-3;
    color: $color-primary;
    text-wrap: balance;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-big;
    }
  }
  &__descripcion {
    font-size: $font-size-big;
  }
}

.publica {
  padding: $space-5 0;
  background-color: $color-primary-light;
}

.card-columns {
  // display: flex;
  // flex-flow: column wrap;
  // gap: $font-size;
  // column-count: 3;
  // column-gap: $font-size;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $font-size;
  @media screen and (max-width: 991px) {
    column-count: 2;
  }
  @media screen and (max-width: 767px) {
    column-count: 1;
  }
}

// @media (min-width: 576px) {
//   .tab-pane .card-columns {
//     column-count: 1;
//   }
// }

// @media (min-width: 768px) {
//   .tab-pane .card-columns {
//     column-count: 2;
//   }
// }

// @media (min-width: 992px) {
//   .tab-pane .card-columns {
//     column-count: 2;
//   }
// }

// @media (min-width: 1200px) {
//   .tab-pane .card-columns {
//     column-count: 3;
//   }
// }

.nav.nav-pills {
  gap: calc($font-size / 2);
}

.nav-item.nav-link.active {
  background-color: $color-primary-dark;
}
.nav-item.nav-link {
  color: $color-primary-dark;
  background-color: $color-light;
}

.publicacion {
  background: $color-white;
  overflow: hidden;
  border-radius: $border-radius;
  margin-bottom: $space-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__body {
    padding: $font-size;
  }

  &__date {
  }

  &__title {
    margin-bottom: $font-size-small;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  &__isbn {
    font-size: $font-size-small;
  }

  &__footer {
    padding: $font-size;
    background: $color-grey-6;
    font-size: $font-size-smaller;
    border-top: 1px solid $color-grey-5;
  }

  &__footer-quote {
  }
}
