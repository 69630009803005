.sobre-principios {
  padding: $space-2 0;
  background: $color-light;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    @include container-100;
  }

  &__titulo {
    font-size: $header-1;
    font-family: $typo-alternative;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }
  &__transversales {
    margin-bottom: $space-3;
  }
  &__cols {
    display: flex;
    gap: $space-3;
    justify-content: space-between;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 767px) {
      gap: $space-2;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__col-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: $space-2;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
      margin-bottom: $font-size;
    }
  }

  &__item {
    flex: 1;
  }
  &__item-cover {
    max-width: 100%;
    height: auto;
    border-radius: $border-radius-min;
  }

  &__item-titulo {
    font-size: $header-2;
    font-family: $typo-alternative;
    margin-bottom: $space-1;
    color: $color-accent;
    padding-top: $space-1;
    margin-top: $space-1;
    border-top: 1px solid $color-dark-alt;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      margin-bottom: $font-size;
      padding-top: $font-size;
      font-size: $header-2-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }

  &__item-subtitulo {
    font-size: $header-3;
    margin-bottom: $font-size;
    color: $color-dark;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      font-size: $header-3-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size-big;
    }
  }

  &__item-descripcion {
    color: $color-dark-alt;
  }
  &__item-dimensiones {
    display: flex;
    gap: $font-size;
    flex-wrap: wrap;
    // @media screen and (max-width: 480px) {
    //   flex-direction: column;
    //   gap: 0.5rem;
    // }
  }
  &__item-dimension {
    background: $color-primary;
    color: $color-white;
    padding: calc($font-size / 4) calc($font-size);
    border-radius: $border-radius-min;
    text-align: center;
  }
}

.sobre-cercles {
  padding: $space-4 0;
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    border: 0 !important;
    padding-top: 0 !important;
    @include container-100;
    @media screen and (max-width: 991px) {
      overflow-x: auto;
    }
  }
}

.sobre-content-grid {
  background-color: $color-primary-light;
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &--light {
    background: $color-light;
  }
  &__header {
    margin-bottom: $space-1;
    @media screen and (max-width: 575px) {
      margin-bottom: $font-size;
    }
  }
  &__descripcion {
    margin-bottom: $space-3;
    text-wrap: balance;
  }
  &__titulo {
    font-size: $header-1;
    font-family: $typo-alternative;
    color: $color-primary-dark;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }

  &__container {
    @include container-100;
  }

  &__col {
    width: 100%;
  }

  &__3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $space-2;
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-2;
    margin-bottom: $space-3;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
}

.transversales {
  display: flex;
  flex-wrap: wrap;
  gap: $space-1;
  @media screen and (max-width: 767px) {
    gap: $font-size;
  }
  &__item {
    flex: 30%;
    background: $color-white;
    padding: $font-size;
    border-radius: $border-radius;
    @media screen and (max-width: 991px) {
      flex: 0 0 0.5;
    }
    @media screen and (max-width: 767px) {
      flex: 0 0 1;
    }
  }
  &__titulo {
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    margin-bottom: calc($font-size / 2);
  }

  &__descripcion {
    color: $color-grey-3;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

.know-how {
  display: flex;
  gap: $space-3;
  @media screen and (max-width: 991px) {
    gap: $space-2;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: $space-1;
  }
  &__cover {
    flex: 0 0 15vw;
    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 991px) {
      flex: 0 0 20vw;
    }
    @media screen and (max-width: 480px) {
      max-width: 124px;
      max-height: 124px;
      // display: none;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: $font-size;
    }
  }

  &__item {
  }

  &__ano {
    font-size: $header-2;
    color: $color-accent;
    margin-bottom: $font-size;
    font-family: $typo-alternative;
    @media screen and (max-width: 991px) {
      margin-bottom: 0.25rem;
    }
  }

  &__titulo {
    font-size: $header-4;
    margin-bottom: $font-size;
    font-weight: $font-weight-bold;
    color: $color-dark;
    @media screen and (max-width: 991px) {
      margin-bottom: 0.5rem;
    }
  }

  &__descripcion {
    color: $color-dark-alt;
  }
}

.kh {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $space-2;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
