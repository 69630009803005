.menu-principal {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: $space-1;
  padding: 0;
  margin: 0;
  & > li.menu-item {
    a {
      color: $color-grey-2;
      text-decoration: none;
      font-weight: $font-weight-medium;
    }
    &.current-menu-item {
      a {
        color: $color-secondary-dark;
      }
    }
  }
  li.menu-item-has-children {
    position: relative;
    padding-right: 0.5rem;
    & > a {
      padding-right: $font-size-small;
      position: relative;
      &:hover {
      }
      &::before {
        content: "\eb3a";
        position: absolute;
        display: block;
        font-family: "unicons-line";
        font-size: $font-size-big;
        background-size: auto;
        width: 9px;
        height: 9px;
        opacity: 0.6;
        right: 0;
        top: 0;
      }
    }
    .sub-menu {
      background-color: $color-white;
      border: 1px solid $color-grey-6;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: absolute;
      box-shadow: $box-shadow;
      max-width: 200px;
      min-width: 150px;
      top: 2rem;
      z-index: 2;
      border-radius: 5px;
      li {
        a {
          color: $color-dark !important;
          font-size: $font-size;
          padding: 0.5rem 1.25rem;
          display: block;
          text-decoration: none;
          font-size: $font-size;
          font-weight: $font-weight-medium;
          &:hover {
            background-color: $color-grey-5;
            color: $color-dark;
          }
        }
      }
    }
  }
}

.off-nav {
  &__nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    & > li.menu-item {
      margin-bottom: calc($font-size-smaller / 2);
      a {
        color: $color-grey-2 !important;
        text-decoration: none;
        font-weight: $font-weight-medium;
        font-size: $header-3;
      }
      &.current-menu-item {
        a {
          color: $color-secondary-dark;
        }
      }
    }
  }
}

.off {
  display: flex;
  flex-direction: column;
  gap: $space-1;
  &__nav {
    padding-bottom: $space-1;
    border-bottom: 1px dashed $color-grey-3;
  }
  &__actions {
    padding-bottom: $space-1;
    border-bottom: 1px dashed $color-grey-3;
  }
  &__social {
    .widget-footer {
      &__title {
        margin-bottom: $font-size;
        color: $color-grey-2;
      }
    }
  }
}

.offcanvas {
  &-title {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
  }
}

.offcanvas-rg {
  .offcanvas-title {
    font-size: $font-size;
    font-weight: $font-weight-bold;
  }
}

.nav-terms {
  display: flex;
  flex-wrap: wrap;
  gap: calc($font-size / 2) $font-size-big;
  &__item {
    a {
      padding: 0.5rem 1rem;
      text-decoration: none !important;
      font-size: $font-size;
      color: $color-primary-dark;
      background-color: $color-white;
      display: inline-block;
      border-radius: $border-radius;
      cursor: pointer;
    }

    &--active {
      a {
        background: $color-primary;
        color: $color-white;
      }
    }
  }
}

.post-type-archive-proyecto .nav-terms-all {
  a {
    background: $color-primary;
    color: $color-white;
  }
}
