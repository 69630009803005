.clientesGrupo {
  // background-color: $color-grey-6;
  // padding: $space-4 0 calc($space-4 - $space-3) 0;
}
.tags-clientes {
  // margin-bottom: $space-1;
  // @media screen and (max-width: 767px) {
  //   margin-bottom: $space-2;
  // }
  // @media screen and (max-width: 575px) {
  //   margin-bottom: $space-1;
  // }
  &__lista {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__term {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    @media screen and (max-width: 991px) {
      font-size: 0.875rem;
      font-weight: normal;
      padding: 0.4rem 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      padding: 0.25rem 0.6rem;
    }
  }

  &__accordion {
    @media screen and (min-width: 992px) {
      display: none;
    }
    .accordion {
    }
    .accordion-item {
      border: none;
      background-color: $color-grey-6;
    }
    .accordion-header {
      display: flex;
      align-items: flex-start;
    }
    .accordion-button {
      box-shadow: none;
      display: flex;
      width: max-content;
      // padding: 0.6rem 1.25rem !important; // cambiar tamaño
      font-size: $font-size-small !important;
      border-radius: 50px !important; // cambiar radius

      &::after {
        margin-left: $font-size;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button:focus {
      border-color: transparent;
    }
  }

  &__accordion--tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.125rem;
  }
  &__accordion--term {
    font-weight: $font-weight-medium;
    font-size: $font-size-smaller;
  }
}

.clientes-tipo {
  &__container {
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: $space-3 0;
    border-top: 1px solid #726f68;
    // &:nth-child(odd) {
    //   background-color: $color-grey-6;
    // }
    // &:nth-child(even) {
    //   background-color: $color-grey-5;
    // }
    &:nth-child(1) {
      border-top: none;
    }
  }

  &__titulo {
    font-size: $header-1;
    font-family: $typo-alternative;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }
}
.clientes {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: $space-4 $space-2;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3 $space-2;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: $space-2;
  }

  &__item {
    background-color: $color-white;
    box-shadow: 0px 0px 8px rgba(30, 0, 40, 0.08);
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    img {
      width: 100%;
      height: auto;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}
.cliente {
  position: relative;
  display: block;
  text-decoration: none;
  color: $color-grey-1;
  &__cover {
    position: relative;
    margin-bottom: $space-1;
    background-color: $color-white;
    box-shadow: $box-shadow;
    box-shadow: 0px 0px 8px rgba(30, 0, 40, 0.08) !important;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    img {
      width: 100%;
      height: auto;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
  &__titulo {
    transition: $trans;
    // text-wrap: balance;
    font-size: $header-4;
    font-weight: $font-weight-bold;
    color: $color-dark;
  }
}

.partners {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: $space-4 $space-2;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3 $space-2;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: $space-2;
  }
}

.thumb-partner {
  &__img {
    background-color: $color-white;
    box-shadow: 0px 0px 8px rgba(30, 0, 40, 0.08);
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    img,
    svg {
      width: 100%;
      height: auto;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
    }
  }
}

.g-clientes {
  padding: $space-2 0;
  // background-color: $color-primary-light;
  @media screen and (max-width: 767px) {
    padding: $space-2 0 $space-2;
  }
  &__header {
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }
  &__container {
    @include container-100;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: calc($font-size / 3);
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    position: relative;
    // margin-bottom: $space-1;
    background-color: $color-white;
    // box-shadow: $box-shadow;
    // box-shadow: 0px 0px 8px rgba(30, 0, 40, 0.08) !important;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-min;
    img {
      width: 100%;
      height: auto;
      max-width: 70%;
      max-height: 50%;
      object-fit: contain;
    }
  }
}
