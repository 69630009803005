.thumb-proyecto {
  position: relative;
  display: block;
  text-decoration: none;
  color: $color-grey-1;
  &__cover {
    position: relative;
    margin-bottom: $space-1;
    box-shadow: $box-shadow;
    box-shadow: 0px 0px 8px rgba(30, 0, 40, 0.08) !important;
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 6 / 4;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: $trans;
    transition: all 0.5s ease;
  }
  &__titulo {
    transition: $trans;
    // text-wrap: balance;
    font-size: $header-4;
    font-weight: $font-weight-bold;
    color: $color-dark;
  }
  &__cliente {
    z-index: 2;
    font-size: $font-size-smaller;
    margin-bottom: calc($font-size / 2);
    span {
      padding-right: calc($font-size / 2);
    }
  }
}

.page-proyectos {
  padding: $space-3 0;
  background: $color-secondary-light;
  background-color: $color-light;
  &__container {
    @include container-100;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-4 $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3 $space-2;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
    .thumb-proyecto.type-publicacion {
      display: none;
    }
  }

  &__terms {
    margin-bottom: $space-2;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: $space-1;
  margin-top: $space-2;
  &__back {
  }
  &__next {
  }
}

// Página de proyecto simple ---------------
.project {
  background-color: $color-light;
  padding: $space-2 0 $space-4;
  &__container {
    @include container-100;
  }
  &__grid {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__content {
  }

  &__resumen {
    font-size: $header-2;
    margin-bottom: $space-2;
    @include typo-alt-400;
  }

  &__cover {
    margin-bottom: $space-1;
    display: block;
    img {
      border: 1px solid $color-grey-5;
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 4 / 2.7;
    }
  }

  &__sidebar {
  }
}

.data-project {
  margin-bottom: $space-2;
  &__title {
    font-size: $header-4;
    font-weight: $font-weight-medium;
    margin-bottom: calc($font-size / 2);
    font-family: $typo-alternative;
  }

  &__content {
    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
  &__cover {
    img {
      max-width: 220px;
      height: auto;
      padding: calc($font-size / 2);
      background-color: $color-white;
    }
  }
  &__link-web {
    border-top: 1px dashed $color-grey-3;
    padding-top: $font-size;
    margin-top: $font-size;
    a {
      display: inline-flex;
      width: auto;
      align-items: center;
      gap: calc($font-size-smaller / 2);
      background-color: $color-white;
      padding: $font-size-smaller $font-size;
      border-radius: $border-radius;
      text-decoration: none;
      color: $color-secondary;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      &:hover {
        background-color: $color-secondary;
        color: $color-white;
      }
    }
  }
}

.project-related {
  padding: $space-4 0;
  background: $color-light;

  &__container {
    @include container-100;
  }

  &__title {
    @include typo-alt-400;
    font-size: $header-3;
    margin-bottom: $space-1;
  }

  &__nav {
  }
}
