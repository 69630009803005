.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}

.titulos {
  margin-bottom: $space-3;
  &--center {
    text-align: center;
  }
  &--50 {
    @media screen and (min-width: 992px) {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--light {
    color: $color-white;
  }
}

.section-title {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: $font-size $space-4;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $font-size;
  }

  &__left {
  }

  &__internal {
    font-family: $typo-alternative;
    font-size: $header-2 !important;
  }

  &__title {
    font-size: $display-2;
    @include typo-alt-400;
    // max-width: 70%;
    @media screen and (max-width: 991px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-1;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-1-res;
    }
  }

  &__description {
    text-wrap: balance;
    font-size: $header-4;
    color: $color-grey-3;
    @media screen and (max-width: 991px) {
      font-size: $font-size-big;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a:not(.boton) {
      color: $color-primary;
      font-size: $header-3;
      text-decoration: none;
      font-weight: $font-weight-medium;
      @media screen and (max-width: 767px) {
        font-size: $header-3-res;
      }
      @media screen and (max-width: 575px) {
        font-size: $font-size-big;
      }
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
  }
  &--white {
    color: $color-white !important;
    .section-title__internal,
    .section-title__description {
      color: $color-white;
    }
    .section-title__title {
      color: $color-grey-2;
    }
  }
}
