.pcontacto {
  padding: $space-4 0;
  background: $color-light;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 575px) {
    padding: $space-2 0;
  }
  &__container {
    @include container-100;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__title {
    font-size: $header-3;
    margin-bottom: $space-1;
    font-weight: $font-weight-bold;
    color: $color-dark;
  }

  &__left {
  }

  &__form {
  }
  &__map {
    iframe {
      border-radius: $border-radius;
      width: 100%;
      height: 50vh;
      filter: grayscale(50%);
    }
  }
  &__descripcion {
    margin-bottom: $space-2;
  }
}

.wc {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $space-1;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-2;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $space-1;
  }
  &__item {
    // background-color: $color-white;
    // padding: $space-1;
    // border-radius: $border-radius;
  }

  &__title {
    margin-bottom: $font-size;
    font-weight: $font-weight-bold;
    color: $color-primary;
    @media screen and (max-width: 767px) {
      font-size: $font-size-big;
      margin-bottom: 0.25rem;
    }
  }

  &__content {
    a {
      color: $color-primary;
      text-decoration: none;
    }
  }
}
